<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="pt-10" id="log">
    <v-form @submit.prevent="">
      <v-layout
        row
        wrap
        class="text-center white pa-10 pl-3 pt-3 mt-15"
        id="login"
      >
        <v-flex xs12 class="mt-4 mb-4">
          <h1 class="display-2 blue--text">Fira Sport</h1>
        </v-flex>
        <v-flex xs12 class="mt-6">
          <v-text-field
            style="font-size: 11px"
            v-model="username"
            :rules="[(v) => !!v || 'Required']"
            label="Username"
            required
            dense
            outlined
            prepend-icon="person"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            style="font-size: 11px"
            v-model="password"
            :rules="[(v) => !!v || 'Required']"
            label="Password"
            required
            dense
            outlined
            type="password"
            prepend-icon="lock"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-btn
            @keyup.enter="adminLoginLoading"
            type="submit"
            dark
            small
            text
            class="primary"
            @click="login"
            id="loginBtn"
            :loading="adminLoginLoading"
          >
            <span class="text-capitalize">Login</span>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    username: "",
    password: "",
    adminLoginLoading: false,
    ipAddress: "",
  }),

  methods: {
    async login() {
      if (this.password != "" && this.username != "") {
        this.adminLoginLoading = true;

        // try {
        //   var adminLoginResult = await axios({
        //     method: "POST",
        //     url: this.$store.state.bingoAdminURL,

        //     data: {
        //       query: `{
        //        adminLogin(user_name:"${this.username}",password:"${this.password}"){
        //              admin_id
        //              user_name
        //              password

        //            }
        //       }
        //       `,
        //     },
        //   });

        //   if (adminLoginResult.data.data.adminLogin.length > 0) {
        //     this.$store.dispatch(
        //       "loginAdmin",
        //       adminLoginResult.data.data.adminLogin[0]
        //     );
        //     this.$router.push({ name: "home" });
        //   } else alert("Please enter correct username and password");
        // } catch (err) {
        //   alert(err);
        // }
        this.$store.dispatch("loginAdmin", "a");
        this.$router.push({ name: "home" });
        this.adminLoginLoading = false;
      } else alert("Please fill all the required fields");
    },
  },
  created() {
    // this.ipAddress = this.$store.state.bingoAdminURL.split(":")[1].split("//")[1];
    // if (this.$store.state.admin != "") this.$router.push({ name: "home" });
    // this.$store.dispatch("loginCashier", false);
  },
};
</script>
<style scoped>
#login {
  max-width: 350px;
  margin: auto;
  border-radius: 5px;
}
#log {
  background-image: url("../assets/back2.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  height: 100vh;
}
</style>
